@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap&subset=latin-ext");

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #0f0f0f;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
